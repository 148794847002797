<div>
    <div class="modal-header">

        <h5 class="modal-title">
            Aprovar Acordo
        </h5>
        <button type="button" class="close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <b>Comprovante</b>
                <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                    [uploader]="uploader" class="dropzone" (click)="fileInput.click()">
                    <span class=" span-icon fas fa-cloud-download-alt"></span>
                    <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
                        carregar</span>
                    <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" multiple/>
                </div>            
                <table class="table" *ngIf="uploader.queue.length > 0">
                    <thead>
                        <tr>
                            <th width="90%">Nome</th>                        
                            <th>Acões</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploader.queue">
                            <td><strong>{{ item?.file?.name }}</strong></td>                                      
                            <td nowrap>
                                <button type="button" class="btn btn-danger" (click)="handleRemoveFile(item)">
                                    <span class="glyphicon glyphicon-trash"></span> Remover
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
        <button type="button" class="btn btn-success ng-star-inserted" (click)="aprovar()"><i class="fas fa-check-circle"></i> Aprovar Acordo</button>        
    </div>
</div>