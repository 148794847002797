import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ChartsModule } from 'ng2-charts';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SortablejsModule } from 'ngx-sortablejs';
import { TextMaskModule } from 'angular2-text-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { NouisliderModule } from 'ng2-nouislider';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { ToastrModule, ToastrService } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { AreaTrabalhoDashboardComponent } from './area-trabalho/area-trabalho.component';
import { AreaTrabalhoRevisorDashboardComponent } from './area-trabalho-revisor/area-trabalho-revisor.component';
import { AgendaDashboardComponent } from './agenda/agenda.component';
import { PublicacoesDashboardComponent } from './publicacoes/publicacoes.component';
import { LayoutModule } from 'src/app/layout/layout.module';
import { LayoutDashboardMenuComponent } from 'src/app/layout/layout-dashboard-menu/layout-dashboard-menu.component';
import { CarteiraDeProcessosComponent } from './carteira-de-processos/carteira-de-processos.component';
import { ProcessosJudiciaisService } from 'src/app/services/processos-judiciais.service';
import { ProcessosJudiciaisRecursoDesdobramentoService } from 'src/app/services/processos-judiciais-recurso-desdobramento.service';
import { ProcessosJudiciaisOcorrenciasService } from 'src/app/services/processos-judiciais-ocorrencias.service';
import { ProcessosJudiciaisHistoricoManualService } from 'src/app/services/processos-judiciais-historico-manual.service';
import { ProcessosJudiciaisOcorrenciaTarefaService } from 'src/app/services/processos-judiciais-ocorrencia-tarefa.service';
import { ProcessosJudiciaisOcorrenciaEventoService } from 'src/app/services/processos-judiciais-ocorrencia-evento.service';
import { ProcessosJudiciaisOcorrenciaPrazoService } from 'src/app/services/processos-judiciais-ocorrencia-prazo.service';
import { ProcessosJudiciaisOcorrenciaAudienciaService } from 'src/app/services/processos-judiciais-ocorrencia-audiencia.service';
import { ProcessoCasoAtendimentoDetalhesService } from 'src/app/services/processo-caso-atendimento-detalhes.service';
import { DocumentoArquivoService } from 'src/app/services/documento-arquivo.service';
import { AtendimentoService } from 'src/app/services/atendimento.service';
import { HonorarioService } from 'src/app/services/honorario.service';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { ModalOcorrenciaComponent } from '../operacionais/processos-judiciais/processos-judiciais-detalhes/modal-ocorrencia/modal-ocorrencia.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalApensosComponent } from '../operacionais/processos-judiciais/processos-judiciais-detalhes/modal-apensos/modal-apensos.component';
import { ModalCadastroUsuarioComponent } from '../administracao/usuario/modal-cadastro/modal-cadastro-usuario.component';
import { ModalService } from 'src/app/services/shared/modal-service';
import { ModalTarefaComponent } from './area-trabalho/modal-tarefa/modal-tarefa.component';
import { ModalTarefaRevisorComponent } from './area-trabalho-revisor/modal-tarefa-revisor/modal-tarefa-revisor.component';
import { ModalCalendarioCobrador } from 'src/app/layout/layout-navbar/modal-calendario-cobrador/modal-calendario-cobrador.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { PrioridadeIconeComponent } from './components/prioridade-icone/prioridade-icone.component';
import { TarefasDetalhesComponent } from './components/tarefas-detalhes/tarefas-detalhes.component';
import { EventosDetalhesComponent } from './components/eventos-detalhes/eventos-detalhes.component';
import { ComentariosDetalhesComponent, SafeHtmlPipe } from './components/comentarios-detalhes/comentarios-detalhes.component';
import { PrazoDetalhesComponent } from './components/prazo-detalhes/prazo-detalhes.component';
import { AudienciaDetalhesComponent } from './components/audiencia-detalhes/audiencia-detalhes.component';
import { HistoricoDetalhesComponent } from './components/historico-detalhes/historico-detalhes.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ModalDetalheComponent } from './agenda/modal-detalhe/modal-detalhe.component';
import { ModalConcluirAtividadeComponent } from './area-trabalho/modal-concluir-atividade/modal-concluir-atividade.component';
import { TarefasUsuarioComponent } from './tarefas-usuario/tarefas-usuario.component';
import { CustomMatPaginatorIntl } from 'src/app/models/shared/CustomPaginatorConfiguration';
import { ContactsComponent } from './contacts/contacts.component';
import { AlertsComponent } from './alerts/alerts.component';
import { MatSelectModule } from '@angular/material/select';
import { AtendimentoRegistroService } from 'src/app/services/atendimento-registro.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalAdicionarTagComponent } from '../operacionais/processos-judiciais/processos-judiciais-detalhes/modal-adicionar-tag/modal-adicionar-tag.component';
import { NgxEditorModule } from 'ngx-editor';
import { MentionModule } from 'angular-mentions'
import { CasoService } from 'src/app/services/caso.service';
import { ModalContatoProcessoCobradorComponent } from '../operacionais/operacao-de-processos/modal-contato-processo-cobrador/modal-contato-processo-cobrador.component';
import { ExportProcessListService } from './carteira-de-processos/export-process-list.service';
import { LancamentosComponent } from './principal-finaceiro/components/lancamentos/lancamentos.component';
import { PrincipalFinaceiroComponent } from './principal-finaceiro/principal-finaceiro.component';
import { ModalHeaderJurosMoratoriosComponent } from '../operacionais/atualizacao-valores/calculation-data/components/modal-header-juros-moratorios/modal-header-juros-moratorios.component';
import { ModalMultaComponent } from '../operacionais/atualizacao-valores/calculation-data/components/modal-multa/modal-multa.component';
import { ModalHonorariosComponent } from '../operacionais/atualizacao-valores/calculation-data/components/modal-honorarios/modal-honorarios.component';
import { ModalEncerramentoComponent } from '../operacionais/atendimento/atendimento-registros/modal-encerramento/modal-encerramento.component';
import { AtendimentoEncerramentoService } from 'src/app/services/atendimento-encerramento.service';
import { ModalHonorariosSucubenciaComponent } from '../operacionais/atualizacao-valores/calculation-data/components/modal-honorarios-sucubencia/modal-honorarios-sucubencia.component';
import { ModalMultaNcpcComponent } from '../operacionais/atualizacao-valores/calculation-data/components/modal-multa-ncpc/modal-multa-ncpc.component';
import { DespesaService } from 'src/app/services/despesa.service';
import { NotaDeDebitoService } from 'src/app/services/notadedebito.service';
import { ExportNotaDebitosService } from '../operacionais/despesas/export-nota-debito';
import { HonorariosService } from '../operacionais/honorarios/honorarios.service';
import { ModalHonorarioConvencionadosComponent } from '../operacionais/honorarios/components/modal-honorario-convencionados/modal-honorario-convencionados.component';
import { ProcessosJudiciaisOcorrenciaComentarioMencaoService } from '../../services/processos-judiciais-ocorrencia-comentario-mencao.service';
import { DespesasDashboardComponent } from './despesas-dashboard/despesas-dashboard.component';
import { ModalEmailCredorComponent } from '../operacionais/operacao-de-processos/modal-email-credor/modal-email-credor.component';
import { ModalAprovarAcordoJudicialComponent } from '../operacionais/components/acordos-processo-judicial/modal-aprovar-acordo-judicial/modal-aprovar-acordo-judicial.component';
import { ModalCadastrarAcordoJudicialComponent } from '../operacionais/components/acordos-processo-judicial/modal-cadastrar-acordo-judicial/modal-cadastrar-acordo-judicial.component';
import { ProcessosJudiciaisAtividadesLogsService } from '../../services/processos-judiciais-atividades-logs.service';
import { GestaoDespesasComponent } from './gestao-despesas/gestao-despesas.component';
import { ModalCadastrarComprovanteComponent } from './gestao-despesas/components/modal-cadastrar-comprovante/modal-cadastrar-comprovante.component';
import { ModalCadastrarNotaDebitoComponent } from './gestao-despesas/components/modal-cadastrar-nota-debito/modal-cadastrar-nota-debito.component';
import { ModalPagamentoNotaDebitoComponent } from './gestao-despesas/components/modal-pagamento-nota-debito/modal-pagamento-nota-debito.component';
//import { GestaoAcordosJudiciaisComponent } from './gestao-acordos-judiciais/gestao-acordos-judiciais.component';
import {GestaoAcordosJudiciaisComponent} from '../operacionais/gestao-acordos-judiciais/gestao-acordos-judiciais.component';
//import { ModalPagamentoComponent } from './gestao-acordos-judiciais/modal-pagamento/modal-pagamento.component';
import { ModalPagamentoComponent } from '../operacionais/gestao-acordos-judiciais/modal-pagamento/modal-pagamento.component';
//import { ModalParcelasComponent } from './gestao-acordos-judiciais/modal-parcelas/modal-parcelas.component';
import {ModalParcelasComponent} from '../operacionais/gestao-acordos-judiciais/modal-parcelas/modal-parcelas.component';
import { LayoutModalAutenticatorComponent } from 'src/app/layout/layout-modal-autenticator/layout-modal-autenticator.component';
import { AprovarAcordoAnexosComponent } from '../operacionais/components/acordos-processo-judicial/aprovar-acordo-anexos/aprovar-acordo-anexos.component';

// *******************************************************************************
//
export function momentAdapterFactory() {
    return adapterFactory(moment);
};
@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        DashboardRoutingModule,
        FullCalendarModule,
        ChartsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        NgSelectModule,
        NgxDatatableModule,

        Ng2SmartTableModule,
        SweetAlert2Module,
        NouisliderModule,
        FileUploadModule,
        NgSelectModule,
        CurrencyMaskModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: false,
            timeOut: 2500,
            tapToDismiss: true,
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-right',
        }),

        LayoutModule,
        SharedModule,
        MatSelectModule,
        MatTooltipModule,
        NgxEditorModule,
        MentionModule,
    ],
    entryComponents: [
        ModalOcorrenciaComponent,
        ModalApensosComponent,
        ModalTarefaComponent,
        ModalTarefaRevisorComponent,
        ModalCalendarioCobrador,
        ModalDetalheComponent,
        ModalConcluirAtividadeComponent,
        ModalHeaderJurosMoratoriosComponent,
        ModalMultaComponent,
        ModalHonorariosComponent,
        ModalAdicionarTagComponent,
        ModalContatoProcessoCobradorComponent,
        ModalEncerramentoComponent,
        ModalHonorariosSucubenciaComponent,
        ModalMultaNcpcComponent,
        ModalHonorarioConvencionadosComponent,
        ModalEmailCredorComponent,
        ModalAprovarAcordoJudicialComponent,
        ModalCadastrarAcordoJudicialComponent,
        ModalCadastrarComprovanteComponent,
        ModalCadastrarNotaDebitoComponent,
        ModalPagamentoNotaDebitoComponent,
        ModalPagamentoComponent,
        ModalParcelasComponent,
        GestaoAcordosJudiciaisComponent,
        LayoutModalAutenticatorComponent,
        AprovarAcordoAnexosComponent
    ],
    declarations: [
        AreaTrabalhoDashboardComponent,
        AreaTrabalhoRevisorDashboardComponent,
        AgendaDashboardComponent,
        PublicacoesDashboardComponent,
        CarteiraDeProcessosComponent,
        ModalTarefaComponent,
        ModalTarefaRevisorComponent,
        PrioridadeIconeComponent,
        TarefasDetalhesComponent,
        EventosDetalhesComponent,
        ComentariosDetalhesComponent,
        PrazoDetalhesComponent,
        AudienciaDetalhesComponent,
        HistoricoDetalhesComponent,
        ModalDetalheComponent,
        ModalConcluirAtividadeComponent,
        TarefasUsuarioComponent,
        ContactsComponent,
        AlertsComponent,
        ModalHeaderJurosMoratoriosComponent,
        ModalMultaComponent,
        ModalHonorariosComponent,
        SafeHtmlPipe,
        LancamentosComponent,
        PrincipalFinaceiroComponent,
        ModalHonorariosSucubenciaComponent,
        ModalMultaNcpcComponent,
        DespesasDashboardComponent,
        GestaoDespesasComponent,
        ModalCadastrarComprovanteComponent,
        ModalCadastrarNotaDebitoComponent,
        ModalPagamentoNotaDebitoComponent,
    ], providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        AtendimentoService,
        AtendimentoRegistroService,
        ArquivoService,
        CasoService,
        HonorarioService,
        DespesaService,
        NotaDeDebitoService,
        ProcessosJudiciaisService,
        ProcessosJudiciaisRecursoDesdobramentoService,
        ProcessosJudiciaisOcorrenciasService,
        ProcessosJudiciaisHistoricoManualService,
        ProcessosJudiciaisOcorrenciaTarefaService,
        ProcessosJudiciaisOcorrenciaEventoService,
        ProcessosJudiciaisOcorrenciaPrazoService,
        ProcessosJudiciaisOcorrenciaAudienciaService,
        ProcessoCasoAtendimentoDetalhesService,
        AtendimentoEncerramentoService,
        DocumentoArquivoService,
        DatePipe,
        ModalService,
        ExportProcessListService,
        ExportNotaDebitosService,
        HonorariosService,
        ProcessosJudiciaisOcorrenciaComentarioMencaoService,
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl
        },
        ProcessosJudiciaisAtividadesLogsService
    ],
    bootstrap: [AgendaDashboardComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [

    ],
})
export class DashboardModule { }
