import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomFileLike } from 'src/app/models/custom-file-like';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';

@Component({
    selector: 'app-aprovar-acordo-anexos',
    templateUrl: './aprovar-acordo-anexos.component.html',
    styleUrls: [
        './aprovar-acordo-anexos.component.scss',
        '../../../../../../vendor/styles/pages/dropzone.scss'
    ]
})
export class AprovarAcordoAnexosComponent implements OnInit {

    constructor(
        public acordoService: AcordoJuridicoService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
    ) { 
        this.uploader = new FileUploader({
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => { });
            },
        });
    }

    private modalRef: NgbModalRef;

    public uploader: FileUploader;

    private files: Array<File> = [];

    public hasBaseDropZoneOver: boolean;

    public referenciaId: any;

    ngOnInit(): void {
        this.uploader.onAfterAddingFile = (file) => {
            this.files.push(new File([file._file], file.file.name, { type: file.file.type }));

            const fileInQueue: any = this.uploader.queue.find(
                (q) => q.file.name === file.file.name
            );
            const newFile = new CustomFileLike(fileInQueue.file);
            fileInQueue.file = newFile;
        };
    }

    dismiss() {
        this.modalRef.close();
    }

    aprovar(){
        let formData: FormData = new FormData();

        this.files.forEach(file => {
            formData.append('files', file);
        });

        this.spinner.show();

        this.acordoService.aprovarAcordoAnexos(this.referenciaId, formData)
            .subscribe(res=>{
                this.spinner.hide();
                this.modalRef.close();
            }, error => {
                    if (error && error.errors) {
                        this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                    }
                    else {
                        this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                    }
                    this.spinner.hide();
                })        
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    onDrop(files: FileList) {
        
        for (let i = 0; i < files.length; i++) {
            
            debugger;
            this.files.push(files[i] as File);
        }

        const fileInQueue: any = this.uploader.queue.find(
            (q) => q.file.name === files[0].name
        );
        const newFile = new CustomFileLike(fileInQueue.file);
        fileInQueue.file = newFile;
    }

    handleRemoveFile(item: FileItem) {
        
        this.files = this.files.filter((c) => c.name != item.file.name);
        this.uploader.queue = this.uploader.queue.filter(
            (c) => c.file.name != item.file.name
        );
    }
}
