<div class="modal-header">

    <h5 class="modal-title" *ngIf="parcela != undefined && acordo!= undefined">
        Pagamento parcela {{ formatarParcela(parcela.parcela, acordo.parcelas)}} do acordo
    </h5>
    <button type="button" class="close" (click)="dismiss()">&times;</button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            Valor dar parcela: <b>{{parcela.valorAberto | currency: utilsService.recuperarTipoCurrency(acordo.moeda)}}</b>
        </div>
        <div class="col-12 mt-2">
            Tipo de acordo: <b>{{parcela.tipoParcela}}</b>
        </div>  
        <div class="col-12 mt-2">
            Data Vencimento: <b>{{parcela.dataVencimento | date:"dd/MM/yyyy" }}</b>
        </div>  
        <div class="col-12 mt-2" *ngIf="parcela != undefined && acordo!= undefined">
            Parcela: <b>{{formatarParcela(parcela.parcela, acordo.parcelas)}}</b>
        </div>        
        <div class="col-12" *ngIf="parcela.tipoParcela != 'Honorário'">
            <div class="p-2">
                <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="ignorarSuccessFee" id="ignorarSuccessFee">
                    <label class="form-check-label mt-1" for="ignorarSuccessFee">
                        Ignorar fluxo de SuccessFee
                    </label>
                </div>                
            </div>
        </div> 
        <div class="col-4">
            <div class="p-2">
                <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                    <span><b style="color: red;">*</b> <strong>Valor do Pagamento</strong></span>
                </div>
                <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                    <input [readonly]="tipoPagamento == 0" currencyMask
                        [options]="options"
                        class="form-control" style="border-color: red"
                        placeholder="" [(ngModel)]="valorPagamentoDivida">
                </div>
            </div>
        </div>   
        <div class="col-4">
            <div class="p-2">
                <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                    <span><strong>Valor do Desconto</strong></span>
                </div>
                <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                    <input [readonly]="tipoPagamento == 0" currencyMask
                        [options]="options"
                        class="form-control" style="border-color: green"
                        placeholder="" [(ngModel)]="valorDescontoDivida">
                </div>
            </div>
        </div>   
        <div class="col-4">
            <div class="p-2">
                <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                    <span><b style="color: red;">*</b> <strong>Data do pagamento</strong></span>
                </div>
                <div class="col-12 pr-0 pl-0 pt-1 pb-1">
                    <p-calendar [(ngModel)]="dataPagamento" dateFormat="dd/mm/yy" slotChar="dd/mm/yyyy" [showIcon]="true" inputId="icon">
                    </p-calendar>                
                </div>
            </div>
        </div>    
    </div>
    <div class="row">
        <div class="col-12">
            <b>Comprovante</b>
            <div *ngIf="uploader.queue.length == 0" ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                [uploader]="uploader" class="dropzone" (click)="fileInput.click()">
                <span class=" span-icon fas fa-cloud-download-alt"></span>
                <span class="span-text">Arraste e solte seus arquivos aqui ou clique para
                    carregar</span>
                <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none" multiple/>
            </div>            
            <table class="table" *ngIf="uploader.queue.length > 0">
                <thead>
                    <tr>
                        <th width="90%">Nome</th>                        
                        <th>Acões</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>{{ item?.file?.name }}</strong></td>                                      
                        <td nowrap>
                            <button type="button" class="btn btn-danger" (click)="handleRemoveFile(item)">
                                <span class="glyphicon glyphicon-trash"></span> Remover
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="false">
        <div class="col-12">
            Dívidas a serem consideradas no pagamento
        </div>
        <div class="col-12">
            <table class="table product-discounts-edit espacamento-tabela-dividas">                
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Nº Documento</th>
                        <th>Vencimento</th>
                        <th class="text-right">Valor Original</th>
                        <th class="text-right">Valor Total Índice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let divida of dividas">
                        <td><input type="checkbox" [checked]="divida.pago" (change)="onChangeDividaPagamento(divida)"></td>
                        <td>{{divida.numero}}</td>
                        <td>{{divida.dtVencimento | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-right">{{divida.valor | currency: utilsService.recuperarTipoCurrency(acordo.moeda)}}</td>
                        <td class="text-right">{{divida.valorTotalCalculado | currency: utilsService.recuperarTipoCurrency(acordo.moeda)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="dismiss()">Fechar</button>
    <button type="button" class="btn btn-primary" (click)="salvar()">Salvar</button>
</div>