<div class="row" *ngIf="loadingAcordos">
    <div class="col-12">
        <div
            style="min-height: 340px;;display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">
            <span>Verificando a existencia de acordos...</span>
            <span>Aguarde alguns instantes...</span>
            <div class="spinner-border spinner-border-sm text-info m-1" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!loadingAcordos">
    <div class="col-12" *ngIf="acordos.length == 0">
        <div style="margin-bottom: 10px; display: flex;flex-direction: column;justify-content: center;">
            <div style="align-self: center;text-align: center;">
                <div class="d-inline-block" placement="bottom-right" ngbDropdown>                    
                    <button type="button" class="btn btn-primary" id="dropdownMenuButtonGerarAcodo" ngbDropdownToggle>
                        <i class="fas fa-plus-circle"></i> Gerar Acordo
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButtonGerarAcodo">
                        <a ngbDropdownItem (click)="abrirModalCadastrarAcordo(0)">Adicionar Todos</a>
                        <a ngbDropdownItem (click)="abrirModalCadastrarAcordo(1)">Adicionar Somente Minuta de Acordo</a>
                        <a ngbDropdownItem (click)="abrirModalCadastrarAcordo(2)">Adicionar Somente Honorários Advocatícios</a>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!loadingAcordos && acordos.length > 0">
    <div class="col-12 d-flex justify-content-end align-items-center font-weight-bold pt-3">
        <div class="btn-adicionar" (click)="menuEdivarVisible = !menuEdivarVisible">
            <i class="fas fa-plus"></i>
        </div>
        <div class="menu-detalhes" (mouseover)="menuEdivarVisible = true"
            (mouseleave)="menuEdivarVisible = false"
            [hidden]="!menuEdivarVisible">
            <ul>
                <li><a (click)="abrirModalCadastrarAcordo(0)">Adicionar Todos</a></li>
                <li><a (click)="abrirModalCadastrarAcordo(1)">Adicionar Somente Minuta de Acordo</a></li>
                <li><a (click)="abrirModalCadastrarAcordo(2)">Adicionar Somente Honorários Advocatícios</a></li>
            </ul>
        </div>
    </div>
    <div class="col-12">
        <ul ngbNav #acordosDisplay="ngbNav" class="nav-tabs">            
            <li ngbNavItem>
                <a ngbNavLink>Acordos Vigentes</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive table-modal-tabs-scroll">
                                <table class="table product-discounts-edit">
                                    <thead>
                                        <tr>
                                            <th class="text-nowrap text-center cursor-pointer">Código</th>
                                            <th class="text-nowrap text-center cursor-pointer">Tipo Acordo</th>

                                            <th class="text-nowrap text-center cursor-pointer">Valor Valor Principal
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Valor dos Honorários
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Data Inicial
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Data Final
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let acordo of acordosVingentes">
                                            <td class="text-center text-nowrap align-middle">
                                                <strong>{{acordo.idExternoCabecalho}}</strong>
                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.tipoAcordoJudicial}}
                                            </td>

                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.valorTotal}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{
                                                acordo.valorHononario
                                                }}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.dataInicial | date: 'dd/MM/yyyy'}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.dataFinal | date: 'dd/MM/yyyy'}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="abrirModalAcordo(acordo)">
                                                    <i class="fa fa-plus-circle"></i>
                                                </button>
                                                <button type="button" class="btn btn-secondary btn-sm" *ngIf="!acordo.descumprido && acordo.tipoAcordoJudicial != 'Honorário'" placement="left" ngbTooltip="Descumpriu Acordo"
                                                    (click)="descumpriuAcordo(acordo)">
                                                    <i class="fas fa-user-times"></i>
                                                </button>
                                                <button type="button" class="btn btn-success btn-sm"
                                                    (click)="exportarAcordo(acordo.referenciaId)" >
                                                    <i class="fas fa-file-export"></i>
                                                </button>
                                                <button type="button" class="btn btn-danger btn-sm"
                                                    (click)="deletarAcordo(acordo, false)"><i
                                                        class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem *ngIf="acordosDescumpridos != undefined && acordosDescumpridos.length">
                <a ngbNavLink>Acordos Descumpridos</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive table-modal-tabs-scroll">
                                <table class="table product-discounts-edit">
                                    <thead>
                                        <tr>
                                            <th class="text-nowrap text-center cursor-pointer">Código</th>
                                            <th class="text-nowrap text-center cursor-pointer">Tipo Acordo</th>

                                            <th class="text-nowrap text-center cursor-pointer">Valor Valor Principal
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Valor dos Honorários
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Data Inicial
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Data Final
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let acordo of acordosDescumpridos">
                                            <td class="text-center text-nowrap align-middle">
                                                <strong>{{acordo.idExternoCabecalho}}</strong>
                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.tipoAcordoJudicial}}
                                            </td>

                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.valorTotal}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{
                                                acordo.valorHononario
                                                }}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.dataInicial | date: 'dd/MM/yyyy'}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.dataFinal | date: 'dd/MM/yyyy'}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="abrirModalAcordo(acordo)">
                                                    <i class="fa fa-plus-circle"></i>
                                                </button>
                                                <button type="button" class="btn btn-success btn-sm"
                                                    (click)="exportarAcordo(acordo.referenciaId)" >
                                                    <i class="fas fa-file-export"></i>
                                                </button>                                                
                                                <button type="button" class="btn btn-info btn-sm" *ngIf="!acordo.acordoRenegociado"
                                                        placement="left" ngbTooltip="Renegociação"
                                                        (click)="abrirModalCadastrarAcordo(3, acordo.id)">
                                                    <i class="fas fa-hand-holding-usd"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink (click)="carregarParcelas()">Extrato de Pagamentos</a>
                <ng-template ngbNavContent>
                    <div class="row" *ngIf="loadAcordosParcelas">
                        <div class="col-12">
                            <div
                                style="min-height: 340px;;display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center;">
                                <span>Carregando extrato...</span>
                                <span>Aguarde alguns instantes...</span>
                                <div class="spinner-border spinner-border-sm text-info m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="text-center mt-5"
                                    *ngIf="!loadAcordosParcelas && (acordosParcelasPorProcesso == undefinde || acordosParcelasPorProcesso.length == 0)">
                                    <h3>Não foram localizas parcelas</h3>
                                </div>
                                <div class="row" *ngIf="!loadAcordosEmAberto && acordosParcelasPorProcesso != undefined && acordosParcelasPorProcesso.length > 0">
                                    <div class="col-12">
                                        <div class="table-responsive">
                                            <table class="table table-striped table-bordered card-table">
                                                <thead>
                                                    <tr>
                                                        <th colspan="4" class="text-center">Sintético</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-nowrap">
                                                            Tipo
                                                        </th>
                                                        <th class="text-nowrap">
                                                            Valor Total
                                                        </th>
                                                        <th class="text-nowrap">
                                                            Valores Pagos
                                                        </th>
                                                        <th class="text-nowrap">
                                                            Valores Desconto
                                                        </th>
                                                        <th class="text-nowrap">
                                                            Saldo
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let total of totaisAcordo">
                                                        <td>{{total.tipoAcordo}}</td>
                                                        <td>{{total.valorPrincipal | currency: 'BRL'}}</td>
                                                        <td>{{total.valorPago | currency: 'BRL'}}</td>
                                                        <td>{{total.valorDesconto | currency: 'BRL'}}</td>
                                                        <td>{{total.saldo | currency: 'BRL'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <br /><br /><br />
                                <hr />
                                <div class="table-responsive" *ngIf="!loadAcordosEmAberto && acordosParcelasPorProcesso != undefined && acordosParcelasPorProcesso.length > 0">
                                    <table class="table table-striped table-bordered card-table">
                                        <thead>
                                            <tr>
                                                <th colspan="11" class="text-center">Analítico</th>
                                            </tr>
                                            <tr>
                                                <th class="text-nowrap cursor-pointer">
                                                    Código Acordo
                                                </th>
                                                <th class="text-nowrap cursor-pointer">
                                                    Tipo
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Situação do Acordo
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Vencimento
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Parcela
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Valor Principal
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Valor Parcela
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Valores Pagos
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Data Pgto.
                                                </th>
                                                <th class="text-nowrap text-center cursor-pointer">
                                                    Saldo
                                                </th>

                                                <th class="text-center text-nowrap align-middle">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let acordos of acordosParcelasPorProcessoAgrupado">
                                                <ng-container *ngFor="let acordo of acordos; let i = index">
                                                    <tr>
                                                        <td class="text-center">{{acordo.idExterno}}</td>
                                                        <td class="text-center">{{acordo.tipoAcordo}}</td>
                                                        <td class="text-center">{{acordo.situacaoAcordo}}</td>
                                                        <td class="text-center">{{acordo.dataVencimento | date: 'dd/MM/yy'}}
                                                        </td>
                                                        <td class="text-center">{{formatarParcela(acordo)}}</td>
                                                        <td class="text-center">{{acordo.valorPrincipal | currency: 'BRL'}}</td>
                                                        <td class="text-center">{{acordo.valorAberto | currency: 'BRL'}}</td>
                                                        <td class="text-center">{{acordo.valorPago | currency: 'BRL'}}</td>
                                                        <td class="text-center">{{acordo.dataPagamento | date: 'dd/MM/yy'}}</td>
                                                        <td class="text-center">{{acordo.saldo | currency: 'BRL'}}</td>
                                                        <td class="text-center text-nowrap align-middle">
                                                            <button type="button" class="btn btn-primary btn-sm" ngbTooltip="Detalhes" ngbTooltip="Comprovante de pagamento"
                                                                (click)="abrirModalAcordo(acordo)">
                                                                <i class="fas fa-external-link-alt"></i>
                                                            </button>
                                                            <button *ngIf="!acordo.valorPago" type="button" class="btn btn-primary btn-sm" ngbTooltip="Pagamento" (click)="abrirModalPagamentoAcordo(acordo)">
                                                                <i class="fas fa-file-invoice-dollar"></i>
                                                            </button>
                                                            <button *ngIf="acordo.arquivoId" type="button"
                                                                class="btn btn-warning btn-sm" (click)="downloadAnexo(acordo)">
                                                                <i class="fas fa-paperclip"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="i == (acordos.length-1)" class="table-primary">
                                                        <th class="text-right" colspan="5">Totais:</th>
                                                        <td class="text-center">
                                                            {{obterTotalAcordos(acordos, 'valorPrincipal') | currency: 'BRL'}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{obterTotalAcordos(acordos, 'valorAberto') | currency: 'BRL'}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{obterTotalAcordos(acordos, 'valorPago') | currency: 'BRL'}}
                                                        </td>
                                                        <td>
                                                            
                                                        </td>
                                                        <td class="text-center">
                                                            {{obterTotalAcordos(acordos, 'saldo') | currency: 'BRL'}}
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </ng-container>                                                
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Simulações</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-12">                            
                            <div class="text-center mt-5" *ngIf="acordosEmAnalise == undefinded || !acordosEmAnalise.length">
                                    <h3>Não foram localizas simulações</h3>
                                </div>
                            <div class="table-responsive table-modal-tabs-scroll" *ngIf="acordosEmAnalise != undefined && acordosEmAnalise.length">
                                <table class="table product-discounts-edit">
                                    <thead>

                                        <tr>
                                            <th class="text-nowrap text-center cursor-pointer">Código
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Tipo Acordo</th>

                                            <th class="text-nowrap text-center cursor-pointer">Valor Valor Principal
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Valor dos Honorários
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Data Inicial
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Data Final
                                            </th>
                                            <th class="text-nowrap text-center cursor-pointer">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let acordo of acordosEmAnalise">
                                            <td class="text-center text-nowrap align-middle">
                                                <strong>{{acordo.idExternoCabecalho}}</strong>
                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.tipoAcordoJudicial}}
                                            </td>

                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.valorTotal}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{
                                                acordo.valorHononario
                                                }}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.dataInicial | date: 'dd/MM/yyyy'}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                {{acordo.dataFinal | date: 'dd/MM/yyyy'}}

                                            </td>
                                            <td class="text-center text-nowrap align-middle">
                                                <button type="button" class="btn btn-warning btn-sm"
                                                    (click)="editarAcordo(acordo)"><i class="fas fa-edit"></i></button>
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="detalheAcordo(acordo.acordos)"><i
                                                        class="fas fa-external-link-alt"></i></button>
                                                <button type="button" class="btn btn-success btn-sm"
                                                (click)="exportarAcordo(acordo.referenciaId)"><i
                                                    class="fas fa-file-export"></i></button>
                                                <button type="button" class="btn btn-danger btn-sm"
                                                    (click)="deletarAcordo(acordo, true)"><i
                                                        class="fas fa-trash-alt"></i></button>
                                                <!--<button *ngIf="checkDescumprimentoButton(acordo)" type="button"
                                                    (click)=" modalGerarDescumprimento(acordo.ocorrenciaId)" class="btn btn-danger btn-sm"
                                                    [disabled]="options.readonly">
                                                    Descumpriu</button> -->

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="col-12 mt-2 border-card" [ngbNavOutlet]="acordosDisplay"></div>
</div>