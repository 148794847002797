import { Ocorrencia } from '../ocorrencia/ocorrencia';
import { TipoAcordo } from '../../../enums/Acordo/tipo-acordo.enum';
import { Guid } from 'guid-typescript';
import { ProcessoDivida } from '../operacao/processo-divida';


export class AcordoJuridico {
    id?: Guid;
    acordoVinculado:number;
    referenciaId: Guid;
    idExterno:number;
    processoId: Guid;
    processoJudicialRecursoDesdobramentoId: Guid;
    ocorrenciaId: Guid;
    ocorrencia: Ocorrencia;
    inicio: Date;
    parcelas: number;
    dia: number;
    diaFixo: Boolean;
    tipo: String;
    tipoNome: String;
    tipoAcordoJudicial: String;
    tipoAcordoVinculadoJudicial: String;
    desconto: String;
    dividas: ProcessoDivida[];
    percentualJuros: number;
    juros: number;
    percentualMulta: number;
    multa: number;
    custas: number;
    atualizacaoCusta: number;
    percentualTaxaAdministrativa: number;
    percentualAtualizacaoCusta: number;
    percentualIndiceCorrecao : number;
    percentualHonorarioAdvocaticioDescumprimento : number;
    taxaAdministrativa: number;
    protesto: number;
    valorAberto: number;
    valorOriginal: number;
    valorTotal: number;
    valorAtualizacaoCustas: number;
    emails: String[];
    descricao: String;
    totalEmAberto: number;
    justificativa: string;
    indiceId: Guid;
    aprovado: Boolean;
    valorConfesado: number;
    moeda: number;
    acordoRenegociacaoId?: Guid;
}
