import { HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SelectList } from "../models/select-list";
import { RequisicaoBuscaPaginada } from "../models/shared/busca-paginada";

@Injectable()
export class UtilsService {

    constructor() {

    }

    recuperarTipoCurrency(moeda){
        debugger;
        switch(moeda){
            case 1:
                return 'USD';
            case 2:
                return 'EUR';
            default:
                return 'BRL';
        }
    }

    buscarPrefix(moeda){
        switch(moeda){
            case 1:
                return '$';
            case 2:
                return '€';
            default:
                return 'R$';
        }
    }
    
    validarCnpjCpf(val : string) {
      if (val.length <= 11) {
         return this.validarCpf(val);
      } else {
         return this.validarCnpj(val);
      }
    }

    validarCnpj(val) {

        var cnpj = val.trim();

        cnpj = cnpj.replace(/\./g, '');
        cnpj = cnpj.replace('-', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cnpj.length > i; i++) {
            if (cnpj[i - 1] != cnpj[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v1 += cnpj[i] * p1;
            } else {
                v1 += cnpj[i] * p2;
            }
        }

        v1 = (v1 % 11);

        if (v1 < 2) {
            v1 = 0;
        } else {
            v1 = (11 - v1);
        }

        if (v1 != cnpj[12]) {
            return false;
        }

        for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v2 += cnpj[i] * p1;
            } else {
                v2 += cnpj[i] * p2;
            }
        }

        v2 = (v2 % 11);

        if (v2 < 2) {
            v2 = 0;
        } else {
            v2 = (11 - v2);
        }

        if (v2 != cnpj[13]) {
            return false;
        } else {
            return true;
        }
    }

    validarCpf(val) {
        var cpf = val.trim();

        //caso seja um cpf de 10 digitos , adicionar um zero a esquerda
        if(cpf.length == 10)
        {
            cpf = "0"+cpf;
        }

        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
            v1 = 0;
        }

        if (v1 != cpf[9]) {
            return false;
        }

        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
            v2 = 0;
        }

        if (v2 != cpf[10]) {
            return false;
        } else {
            return true;
        }

    }

    formatarCnpj(cnpj) {
        var cnpjFormatado = '';
        let cnpjLocal = cnpj.trim();

        if (cnpjLocal.length == 14) {
            cnpjFormatado = cnpjLocal.substr(0, 2) + '.' + cnpjLocal.substr(2, 3) + '.' + cnpjLocal.substr(5, 3) + '/' + cnpjLocal.substr(8, 4) + '-' + cnpjLocal.substr(12, 2);
        } else {
            return cnpjLocal;
        }

        return cnpjFormatado;
    }

    formatarCpf(cpf) {
        var cpfFormatado = '';
        let cpfLocal = cpf.trim();

        if (cpfLocal.length == 11) {
            cpfFormatado = cpfLocal.substr(0, 3) + '.' + cpfLocal.substr(4, 3) + '.' + cpfLocal.substr(7, 3) + '-' + cpfLocal.substr(9, 2);
        }
        else {
            return cpfLocal;
        }

        return cpfFormatado;
    }

    formatarTextoBorderoParaData(data) {
        try {
            var dataSplit = data.split('/');

            var ano = dataSplit[2].length == 4 ? parseFloat(dataSplit[2]) : parseFloat('20' + dataSplit[2]);
            var mes = parseFloat(dataSplit[0]) - 1;
            var dia = parseFloat(dataSplit[1]) + 1;

            if(mes <= 0 || dia <= 0 || mes > 12 || dia > 31){
                return new Date(Date.UTC(1900, 1, 1, 0, 0, 0));
            }

            return new Date(Date.UTC(ano, mes, dia, 0, 0, 0));
        }
        catch (ex) {
            return new Date(Date.UTC(1900, 1, 1, 0, 0, 0));
        }
    }

    formatarTextoBorderoParaData2(data) {
      try {
          var dataSplit = data.trim().split('/');

          var year = dataSplit[2].length == 4 ? parseFloat(dataSplit[2]) : parseFloat('20' + dataSplit[2]);
          var month = parseFloat(dataSplit[1]) -1;
          var day = parseFloat(dataSplit[0]);

          return new Date(year,month,day);
      }
      catch (ex) {
          return new Date(1900, 1, 1, 0, 0, 0);
      }
    }

    formatarTextoYYYYMMDDParaData(data) {
      try {

          var year = parseFloat(data.substr(0,4));
          var month = parseFloat(data.substr(4,2)) -1;
          var day = parseFloat(data.substr(6,2));

          return new Date(year,month,day);
      }
      catch (ex) {
          return new Date(1900, 1, 1, 0, 0, 0);
      }
    }

    formatarDataNgbDatepickerParaRequisicao(data) {
        
        if (!data)
            return null;

        return new Date(data.year, data.month - 1, data.day);
    }

    validarCampoDataBordero(data) {
        var dataValidacao = new Date(Date.UTC(1901, 1, 1, 0, 0, 0));

        if (dataValidacao > data) {
            return false;
        }

        return true;
    }

    mascaraCpfCnpjCadastro(cpfCnpj) {
        var cpfCnpjLimpo = cpfCnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');

        if (cpfCnpjLimpo.length == 11) {
            return cpfCnpjLimpo.substring(0, 3) + '.' +
                cpfCnpjLimpo.substring(3, 6) + '.' +
                cpfCnpjLimpo.substring(6, 9) + '-' +
                cpfCnpjLimpo.substring(9, 11);
        }
        else {
            return cpfCnpjLimpo.substring(0, 2) + '.' +
                cpfCnpjLimpo.substring(2, 5) + '.' +
                cpfCnpjLimpo.substring(5, 8) + '/' +
                cpfCnpjLimpo.substring(8, 12) + '-' +
                cpfCnpjLimpo.substring(12, 14);
        }
    }

    renderDownload(data: any, fileName: string) {
        switch (data.type) {
            case HttpEventType.Response:
                let body = data.body as BlobPart;
                let type = data.body["type"];

                const downloadedFile = new Blob([data.body as BlobPart], { type: type });
                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                document.body.appendChild(a);
                a.download = fileName;
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);

                break;
        }
    }

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
    }

    stringEnumToKeyValue(stringEnum) {
        const keyValue = [];
        const keys = Object.keys(stringEnum).filter((value, index) => {
            return !(index % 2);
        });

        for (const k of keys) {
            keyValue.push({key: k, value: stringEnum[k]});
        }

        return keyValue;
    }

    clearCpfCnpj(str){
      var cpfCnpjLimpo = str.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
      return cpfCnpjLimpo;
    }

    groupBy(colecao, propriedade) {
      var agrupado = [];
      colecao.forEach(function (i) {
          var foiAgrupado = false;
          agrupado.forEach(function (j) {
              if (j.Key == i[propriedade]) {
                  j.Elements.push(i);
                  foiAgrupado = true;
              }
          });
          if (!foiAgrupado) agrupado.push({ Key: i[propriedade], Elements: [ i ] });
      });
      return agrupado;
    }

    getMesStr(data:Date)
    {
        switch(data.getMonth()){
          case 0: return "Janeiro";
          case 1: return "Fevereiro";
          case 2: return "Março";
          case 3: return "Abril";
          case 4: return "Maio";
          case 5: return "Junho";
          case 6: return "Julho";
          case 7: return "Agosto";
          case 8: return "Setembro";
          case 9: return "Outubro";
          case 10: return "Novembro";
          case 11: return "Dezembro";
        }
    }

    getAnoMesStr(data:Date)
    {
        switch(data.getMonth()){
          case 0: return "Jan/"+data.getFullYear();
          case 1: return "Fev/"+data.getFullYear();
          case 2: return "Mar/"+data.getFullYear();
          case 3: return "Abr/"+data.getFullYear();
          case 4: return "Mai/"+data.getFullYear();
          case 5: return "Jun/"+data.getFullYear();
          case 6: return "Jul/"+data.getFullYear();
          case 7: return "Ago/"+data.getFullYear();
          case 8: return "Set/"+data.getFullYear();
          case 9: return "Out/"+data.getFullYear();
          case 10: return "Nov/"+data.getFullYear();
          case 11: return "Dez/"+data.getFullYear();
        }
    }

    getParametroRequisicaoBuscaPaginada(requisicaoBuscaPaginada: RequisicaoBuscaPaginada){

        let parametrosRequisicaoBuscaPaginada = `paginaAtual=${requisicaoBuscaPaginada.paginaAtual}&itensPorPagina=${requisicaoBuscaPaginada.itensPorPagina}`;

        if (!requisicaoBuscaPaginada.colunaOrdenacao && requisicaoBuscaPaginada.colunaOrdenacao !== undefined) 
            parametrosRequisicaoBuscaPaginada += `&colunaOrdenacao=${requisicaoBuscaPaginada.colunaOrdenacao}`;

        if (!requisicaoBuscaPaginada.direcaoOrdenacao && requisicaoBuscaPaginada.direcaoOrdenacao !== undefined) 
            parametrosRequisicaoBuscaPaginada += `&direcaoOrdenacao=${requisicaoBuscaPaginada.direcaoOrdenacao}`;

        return parametrosRequisicaoBuscaPaginada;
    }

    obterSelectListSimOuNao(): SelectList[] {
        var selectList: SelectList[] = [];

        selectList.push(new SelectList("true", "Sim"));
        selectList.push(new SelectList("false", "Não"));
        
        return selectList;
    }
}
