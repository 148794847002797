import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { error } from 'console';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { ModalParcelasComponent } from './modal-parcelas/modal-parcelas.component';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { NgbDateCustomParserFormatter } from 'src/app/utils/dateformat';
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
    selector: 'app-gestao-acordos-judiciais',
    templateUrl: './gestao-acordos-judiciais.component.html',
    styleUrls: ['./gestao-acordos-judiciais.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class GestaoAcordosJudiciaisComponent implements OnInit {

    public loadAcordosEmAberto: boolean = true;
    public loadAcordosVencemHoje: boolean = true;
    public loadAcordosVencidas: boolean = true;
    public loadGeral: boolean = true;
    public filtroOn: boolean = false;

    public acordosParcelasEmAberto: any =[];
    public acordosParcelasEmAbertoPorParcela: any =[];
    public acordosParcelasVencemHoje: any =[];
    public acordosParcelasVencidas: any =[];
    public acordosParcelasVencidasPorParcela: any =[];
    public acordosParcelasGeral: any =[];
    public raiz: string = '';
    public totalItens: number = 0;        
    public paginaAtual:number = 1;
    public dataInicial: Date = null;
    public dataFinal: Date = null;

    get totalPaginas() {
        return Math.ceil(this.totalItens / this.filtro.itensPorPagina);
    }
    
    public filtro: any = {
        codigoAcordo: <number | null> null,
        pasta:<string> '',
        numeroProcesso:<string>'',
        cliente:<Array<Guid> | null> null,
        envolvido:<Guid | null> null,
        minutaDeAcordo: "0",
        situacao: "0",
        pagina: <number> 1,
        itensPorPagina: <number> 50,
        ordenacao: <string>'razao',
        sortDesc: <boolean> false,
        valorParcelaInicial: <number | null> null,
        valorParcelaFinal: <number | null> null,
        vencimentoInicial: <Date | null> null,
        vencimentoFinal: <Date | null> null,
    }

    //ordenação
    sortByPendentes: string = 'razao';
    sortDescPendentes: boolean = false;
    aba = 1;
    subAba = 1;

    constructor(
        private acordoJuridicoService: AcordoJuridicoService,
        private spinner: NgxSpinnerService,
        public toastrService: ToastrService,
        public modalService: NgbModal,
        private router: Router,
        public utilsService: UtilsService,   
    ) { }

    ngOnInit(): void {
      if (this.router.url.indexOf('/dashboard/') > -1) {
        this.raiz = '/dashboard'
      } else {
        this.raiz = '/operacionais'
      }
        this.carregarAcordos();
    }

    public recuperarTipoCurrency(moeda){         
        
        return this.utilsService.recuperarTipoCurrency(moeda);
    }

    filtrar(){
        this.filtro.paginaAtual=1;

        if(this.aba == 1){
            this.carregarAcordos();
        }else{            
            this.filtro.vencimentoInicial = this.formatarDataParaRequisicao(this.dataInicial);
            this.filtro.vencimentoFinal = this.formatarDataParaRequisicao(this.dataFinal);

            switch(this.subAba){
                case 1:
                    this.carrgarPorParcelaEmAbero(true);
                    break;    
                case 2:
                    this.carrgarPorParcelaVencidas(true);
                    break;
                case 3:
                    this.carregarGeral(true);
                    break;
            }                        
        }
    }

    limparFiltros(){
        this.filtro.codigoAcordo = null;
        this.filtro.pasta = '';
        this.filtro.numeroProcesso = '';
        this.filtro.cliente = null;
        this.filtro.envolvido = null;
        this.filtro.minutaDeAcordo = "1";
        this.filtro.vencimentoInicial = null;
        this.filtro.vencimentoFinal = null;
        this.filtro.valorParcelaInicial = null;
        this.filtro.valorParcelaFinal = null;
        this.paginaUm();

        this.filtrar();
    }    

    paginaUm(){
        this.filtro.paginaAtual=1
        this.filtro.itensPorPagina=50;
    }

    sortAcordosParcelasEmAbertoPorParcelaChange(sortObject){
        this.filtro.ordenacao = sortObject.sortBy;
        this.filtro.sortDesc = sortObject.sortDesc;
        this.filtro.pagina = 1;
        this.carrgarPorParcelaEmAbero(true);
    }

    public sortByAcordosParcelasEmAbertoPorParcela: string = 'razao';
    public sortDescAcordosParcelasEmAbertoPorParcela: boolean = false;

    carrgarPorParcelaEmAbero(paginaUm: boolean){

        this.subAba = 1;        

        if(paginaUm){
            this.paginaUm();
        }        
        
        this.loadAcordosEmAberto = true;
        
        this.acordoJuridicoService.obterAcordosParcelasEmAbertoPorParcela(this.filtro)
            .subscribe(res=>{
                this.loadAcordosEmAberto = false;
                this.alimentarAcordosParcelasEmAbertoPorParcela(res.data.itens);
                this.totalItens = res.data.totalItens;
            },err=>{
                this.toastrService.error('Não foi possível buscar acordos em aberto!', 'Atenção', { timeOut: 10000 });
                this.loadAcordosEmAberto = false;
            })
    }

    alterarPaginaEmAberto(pagina: number){
        this.filtro.pagina = pagina;

        this.carrgarPorParcelaEmAbero(false);
    }        

    public sortByAcordosVencidasPorParcela: string = 'razao';
    public sortDescAcordosVencidasPorParcela: boolean = false;

    sortAcordosVencidasPorParcelaChange(sortObject){
        this.filtro.ordenacao = sortObject.sortBy;
        this.filtro.sortDesc = sortObject.sortDesc;
        this.filtro.pagina = 1;
        this.carrgarPorParcelaVencidas(true);
    }

    carrgarPorParcelaVencidas(paginaUm: boolean){
        this.subAba = 2;
        this.loadAcordosVencidas = true;

        if(paginaUm){
            this.paginaUm();
        }
        
        this.acordoJuridicoService.obterAcordosParcelasVencidasPorParcela(this.filtro)
            .subscribe(res=>{
                this.loadAcordosVencidas = false;                
                this.alimentarAcordosParcelasVencidasPorParcela(res.data.itens);
                this.totalItens = res.data.totalItens;
            },err=>{
                this.toastrService.error('Não foi possível buscar acordos vencidos!', 'Atenção', { timeOut: 10000 });
                this.loadAcordosVencidas = false;
            })
    }

    alterarPaginaVencidas(pagina: number){
        this.filtro.pagina = pagina;

        this.carrgarPorParcelaVencidas(false);
    }    

    public sortByAcordosGeral: string = 'razao';
    public sortDescAcordosGeral: boolean = false;

    sortAcordosGeralChange(sortObject){
        this.filtro.ordenacao = sortObject.sortBy;
        this.filtro.sortDesc = sortObject.sortDesc;
        this.filtro.pagina = 1;
        this.carregarGeral(true);
    }

    carregarGeral(paginaUm: boolean){
        this.subAba = 3;

        this.loadGeral = true;

        if(paginaUm){
            this.paginaUm();
        }
        
        this.acordoJuridicoService.obterAcordosParcelasGeral(this.filtro)
            .subscribe(res=>{
                this.loadGeral = false;                
                this.loadAcordosEmAberto = false;
                this.alimentarAcordosGeral(res.data.itens);
                this.totalItens = res.data.totalItens;
            },err=>{
                this.toastrService.error('Não foi possível buscar acordos geral!', 'Atenção', { timeOut: 10000 });
                this.loadGeral = false;
            })
    }

    carregarAcordos() {
        this.aba = 1;

        this.loadAcordosEmAberto = true;   
        this.spinner.show();     

        this.acordoJuridicoService.obterAcordosParcelasEmAberto(this.filtro)
            .subscribe(res=>{
                this.loadAcordosEmAberto = false;
                this.alimentarAcordosEmAberto(res.data.itens);
                this.totalItens = res.data.totalItens;
                this.spinner.hide();
            }, err=>{
                this.loadAcordosEmAberto = true;
                this.spinner.hide();
                this.toastrService.error('Não foi possível buscar acordos em aberto!', 'Atenção', { timeOut: 10000 });
            })        
    }

    alterarPagina(paginaAtual){
        this.filtro.pagina = paginaAtual;
        if(this.aba == 1)
            this.carregarAcordos();
        else
            this.carregarGeral(false);
    }

    carregarPorParcela(){
        this.aba = 2;

        this.carrgarPorParcelaEmAbero(true);

        // this.carrgarPorParcelaVencidas();

        // this.carregarGeral();
    }

    abrirFecharFiltro(){
        this.filtroOn = !this.filtroOn;
    }

    exportarAcordo(referenciaId){
        this.spinner.show();
        this.acordoJuridicoService.export(referenciaId)
            .subscribe(res=> {
                var datePipe = new DatePipe("en-US");
                FileSaver.saveAs(res, "acordo-" + datePipe.transform(new Date(), 'dd-MM-yyyy') + '.xlsx');
                this.spinner.hide();
            },error => {
                if (error && error.errors) {
                    this.toastrService.error(error.errors[0], 'Atenção', { timeOut: 10000 });
                }
                else {
                    this.toastrService.error('Não foi possível baixar!', 'Atenção', { timeOut: 10000 });
                }
                this.spinner.hide();
            });
    }

    alimentarAcordosEmAberto(acordos: any[]){
        this.acordosParcelasEmAberto = acordos;
        console.log(acordos);
        this.sort(this.acordosParcelasEmAberto, this.sortByPendentes, this.sortDescPendentes);
    }

    alimentarAcordosVencemHoje(acordos: any[]){
        this.acordosParcelasVencemHoje = acordos;

        this.sort(this.acordosParcelasVencemHoje, this.sortByPendentes, this.sortDescPendentes);
    }

    alimentarAcordosVencidos(acordos: any[]){
        this.acordosParcelasVencidas = acordos;

        this.sort(this.acordosParcelasVencidas, this.sortByPendentes, this.sortDescPendentes);
    }

    alimentarAcordosParcelasEmAbertoPorParcela(acordos: any[]){
        this.acordosParcelasEmAbertoPorParcela = acordos;

        this.sort(this.acordosParcelasEmAbertoPorParcela, this.sortByPendentes, this.sortDescPendentes);
    }

    alimentarAcordosParcelasVencidasPorParcela(acordos: any[]){
        this.acordosParcelasVencidasPorParcela = acordos;

        this.sort(this.acordosParcelasVencidasPorParcela, this.sortByPendentes, this.sortDescPendentes);
    }

    alimentarAcordosGeral(acordos: any[]){
        this.acordosParcelasGeral = acordos;

        this.sort(this.acordosParcelasGeral, this.sortByPendentes, this.sortDescPendentes);
    }

    setSortPendentes(key) {        
        if (this.sortByPendentes !== key) {
            this.sortByPendentes = key;
            this.sortDescPendentes = false;
        } else {
            this.sortDescPendentes = !this.sortDescPendentes;
        }

        this.filtro.ordenacao = key;
        this.filtro.sortDesc = this.sortDescPendentes;

        //this.sort(this.acordosParcelasEmAberto, this.sortByPendentes, this.sortDescPendentes);
        this.carregarAcordos();
    }

    setSortPendentesPorParcela(key){
        if (this.sortByPendentes !== key) {
            this.sortByPendentes = key;
            this.sortDescPendentes = false;
        } else {
            this.sortDescPendentes = !this.sortDescPendentes;
        }

        this.sort(this.acordosParcelasEmAbertoPorParcela, this.sortByPendentes, this.sortDescPendentes);
    }

    abrirModalAcordo(acordo){
        debugger;
        this.spinner.show();
        const modalRef = this.modalService.open(ModalParcelasComponent, {
            size: "lg",
            backdrop : 'static',
            keyboard : false
        });
        
        modalRef.componentInstance.referenciaId = acordo.referenciaId;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result.then((result) => {
            this.carregarAcordos();
        });
    }

    descumpriuAcordo(acordo){
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title:'Deseja realmente mudar o status do acordo para descumprido?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                this.acordoJuridicoService.descumprirAcordoJudicial(acordo.referenciaId)
                    .subscribe(res =>{
                        this.carregarAcordos();
                        this.spinner.hide();
                    },err=>{
                        this.spinner.hide();
                        this.toastrService.error('Não foi possível descumprir acordo!', 'Atenção', { timeOut: 10000 });
                    })
            }
        });
    }

    deletarAcordo(acordo, simulacao){
        debugger;
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: simulacao ? 'Deseja realmente excluir a simulação?' : 'Deseja realmente excluir o acordo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                this.acordoJuridicoService.delete(acordo.id)
                    .subscribe(res =>{
                        this.carregarAcordos();
                        this.spinner.hide();
                    },err=>{
                        this.spinner.hide();
                        this.toastrService.error('Não foi possível salvar!', 'Atenção', { timeOut: 10000 });
                    })
            }
        });
    }

    sort(data: any, sortBy: string, sortDesc: boolean) {

        data.sort((a: any, b: any) => {
            a = typeof (a[sortBy]) === 'string' ? a[sortBy].toUpperCase() : a[sortBy];
            b = typeof (b[sortBy]) === 'string' ? b[sortBy].toUpperCase() : b[sortBy];

            if (a < b) { return sortDesc ? 1 : -1; }
            if (a > b) { return sortDesc ? -1 : 1; }
            return 0;
        });
    }

    formatarParcela(acordo){
        const parcela = acordo.numeroParcela < 9 ? '0'+ acordo.numeroParcela : acordo.numeroParcela;
        const quantidadeParcelas = acordo.quantidadeParcelas < 9 ? '0'+ acordo.quantidadeParcelas : acordo.quantidadeParcelas;

        return `${parcela}/${quantidadeParcelas}`;
    }

    formatarDataParaRequisicao(data) {
        if (!data) return null;
    
        return new Date(data.year, data.month - 1, data.day);
      }
}
